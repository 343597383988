import React, { createContext, useState, useContext, useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { serverGet } from '../../middleware/http';
import { apiConst } from '../constant/constant';
import { getAuthProfile, getSearchParams } from '../utils/validation';
//import { ima } from '../../asset/images/';

const AccountSettingContext = createContext(null);

export const useAccountSettings = () => {
    return useContext(AccountSettingContext);
};

const accountSettingCode = "DTF,DIPR,CUR,PMTD,GWF,URS,ENSP,ENJN,BECON,AFCON,VCP,BNWTP,CPI,SCL,PHF,PMA,ECI,PFS,PIF,FUA,CIA,ACG,FCI,HMP,AAA,DCS,PCW,ADN,SNP,CBT";

export const AccountSettingsProvider = ({ children }) => {
    const [account, setAccount] = useState(null);
    const [isAuth, setisAuth,] = useState("null");
    const [accountSettings, setAccountSettings] = useState(null);
    const [accountDate, setAccountDate] = useState(null);
    const [showAccPricing, setShowAccPricing] = useState(false);
    const [AccountCurrency, setAccountCurrency] = useState(null);
    const [cateogrySettings, setCateogrySettings] = useState(null);
    const [isGWF, setisGWF] = useState(false);
    const [patientMinimumAge, setPatientMinimumAge] = useState("");
    const [conditionValue, setConditionValue] = useState(null); // Reasonn page conditions
    const [conditionDocLength, setConditionDocLength] = useState(null); // Reasonn page conditions
    const [schedulePricing, setSchedulePricing] = useState(null);
    const [accountModules, setAccountModules] = useState(null);
    const [calenderWeeks, setCalenderWeeks] = useState(null);
    const location = useLocation();

    const getAccountSetting = async () => {
        try {
            const res = await serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(accountSettingCode)}`)
            if (res?.status == 1 && res?.data != null) {
                if (Array.isArray(res?.data)) {
                    const dateSetting = res?.data?.find(em => em?.settingCode == "DTF");
                    if (dateSetting)
                        setAccountDate(dateSetting);
                    const priceSetting = res?.data?.find(em => em?.settingCode == "DIPR" && em?.value == "1");
                    if (priceSetting)
                        setShowAccPricing(true);
                    const account_currency = res?.data?.find(em => em?.settingCode == "CUR");
                    if (account_currency)
                        setAccountCurrency(account_currency);
                    const gwf = res?.data?.find(em => em?.settingCode == "GWF" && em?.value == "1"); //Gaya weelness workflow
                    if (gwf) setisGWF(true)
                    const pma = res?.data?.find(em => em?.settingCode == "PMA")?.value; // patient minimum age 
                    if (pma) setPatientMinimumAge(parseInt(pma));
                    const reasonPageCondition = res?.data?.find(em => em?.settingCode == "FCI")?.value; // Reason doc 
                    if (reasonPageCondition) setConditionValue(reasonPageCondition);
                    const maxDocLength = res?.data?.find(em => em?.settingCode == "HMP")?.value; // condition  maximum docs  
                    if (maxDocLength) setConditionDocLength(maxDocLength);
                    const _calendarWeeks = res?.data?.find(em => em?.settingCode == "PCW")?.value;
                    if (_calendarWeeks) setCalenderWeeks(_calendarWeeks)
                    setAccountSettings(res?.data);
                    return res?.data;
                }
            }
        } catch (e) {
            console.error(e)
        }

    }

    const initAccount = async () => {
        try {
            const settingCode = "HRC,HPC,FTC"
            const res = await serverGet(`${apiConst.gethost}?scodes=${settingCode}`);
            if (res?.status == 1) {
                setAccount(res?.data);

                setFavicon(res?.data?.favicon);

                let auth_res = getAuthProfile.getProfileAuth();
                if (auth_res == null || auth_res == undefined) {
                    const res = await serverGet(apiConst.getauthprofile);
                    auth_res = res?.data?.auth;
                }
                if (auth_res) {
                    getAccountModules(res?.data?.accountId);
                }
            }
        } catch (e) {
            console.log(e);
        }

    }


    const setFavicon = (path) => {
        const link = document.querySelector("link[rel~='icon']");
        let _url = `/favicon.png`;
        //console.log(link);
        if (path) {
            _url = `/doc/getimage?url=${encodeURIComponent(path)}`
        }
        if (!link) {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = _url;
            document.head.appendChild(newLink);
        } else {
            link.href = _url;
        }


    };

    //const returnSetting = (code) => {

    //}

    const getAccountsettingValue = (arr, code) => {

        if (Array.isArray(arr)) {
            return arr?.find((list) => list?.settingCode == code)?.value
        }
    }

    const getAuth = async () => {
        try {
            const res = await serverGet(apiConst.getauthprofile);
            if (res?.status == 1) {
                if (res?.data?.auth) {
                    getAuthProfile.saveProfileAuth();
                    getPatient();
                } else {
                    getAuthProfile.clearProfileAuth();
                }
                setisAuth(res?.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getPatient = () => {

        serverGet(apiConst.patientinfo).then((res) => {

            try {
                if (res?.data != null) {
                    localStorage.setItem("memberId", res?.data?.memberId);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }


    const getCateogry = () => {

        const account_settings = 'schedule_duration,encounter_mode,booking_type';

        serverGet(`${apiConst.getmodes}${account_settings}`).then((res) => {

            try {
                if (res?.data != null) {
                    setCateogrySettings(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const getSchedulePricing = (sId, cId) => {

        const pricingAtr = [
            {
                attributeid: "101",
                recordtypeid: "33",
                recordid: cId ? cId : "1"
            },
            {
                attributeid: "102",
                recordtypeid: "32",
                recordid: sId
            }
        ]


        serverGet(`${apiConst.getAdvancePricing}?atr=${JSON.stringify(pricingAtr)}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data != null) {
                    setSchedulePricing(res?.data?.price)
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const getAccountModules = (id) => {
        serverGet(`${apiConst.getAccountModules}${id}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data != null) {
                    setAccountModules(res?.data)
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    useLayoutEffect(() => {
        if (isAuth == "null" || !isAuth) {
            getAuth();
        }
        if (!accountSettings) {
            const fetchData = async () => {
                try {
                    await getAccountSetting();

                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }

        if (!account)
            initAccount();


    }, [location]);

    useEffect(() => {

        const _typeId = getSearchParams("ftypeid");
        const specialityId = getSearchParams("spid");

        if (specialityId && getAuthProfile.getProfileAuth() && !schedulePricing) {
            getSchedulePricing(specialityId, _typeId);
        }

    }, [location])

    useEffect(() => {
        setTimeout(() => {
            if (getAuthProfile.getProfileAuth() && !cateogrySettings) {
                getCateogry();
            }
        }, 4000)


    }, [location])

    return (
        <AccountSettingContext.Provider value={
            {
                accountDate,
                showAccPricing,
                AccountCurrency,
                isGWF,
                accountSettings,
                account,
                patientMinimumAge,
                cateogrySettings,
                conditionValue,
                conditionDocLength,
                schedulePricing,
                getSchedulePricing,
                accountModules,
                getAccountSetting,
                calenderWeeks,
                getAccountsettingValue
            }
        }
        >
            {children}
        </AccountSettingContext.Provider>
    );
};



