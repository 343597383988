import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { useForm } from 'react-hook-form';
import { serverGet, serverPost } from '../../../middleware/http';
import { apiConst, appConst } from '../../../services/constant/constant';
import NotifyContext from '../../../services/utils/notify';
import ProgressContext from '../../../services/utils/progress';
import { dateFormat, formValidation, getMemberId, getMsg, getSearchParams, getUtcTimeNow, removeSearchParam, timeFormat, updateSearchParams } from '../../../services/utils/validation';
import DoctorSummary from '../consultation/summary/DoctorSummary';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import ActionStatus from '../../../services/utils/actionStatus';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useDoctor } from '../../../services/Context/DoctorContext';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useProfile } from '../../../services/utils/profileContext';
import { useLayoutEffect } from 'react';


const account_settings = 'schedule_duration,encounter_mode'
const slotsPerWeek = 5;
export default function Booking() {
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    const [encounter, setEncounter] = useState([]);
    const [duration, setDuration] = useState([]);
    const [durationId, setDurationId] = useState('');
    const [durationName, setDurationName] = useState('');
    const [durationIndex, setdurationIndex] = useState('');
    const [encIndex, setencIndex] = useState('');
    const [mode, setMode] = useState('');
    const [bookingDate, setBookingDate] = useState(null);
    //const [selectedBookingDate, setSelectedBookingDate] = useState(null);
    //const [bookingDateClick, setBookingDateClick] = useState(false);
    const [slotDays, setSlotDays] = useState([]);
    const [slotTime, setSlotsTime] = useState([]);
    const [selectedSlotTime, setSelectedSlotsTime] = useState([]);
    // const [isEmptySlot, setIsEmptySlot] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [accountTimeZone, setAccountTimeZOne] = useState("");
    const [showTimeSlot, setShowTimeSlot] = useState(false);
    const [currentWeek, setCurrentWeek] = useState(1);
    const [totalCalenderWeek, setTotalCalenderWeek] = useState(0);
    const [totalWeek, setTotalWeek] = useState(0);
    const { progParams, showLoaderProgress, hideLoaderProgress } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const { getSchedulePricing, calenderWeeks } = useAccountSettings();
    const { profileContext } = useProfile();

    const navigate = useNavigate();
    const { getLanguageContent } = useLanguageRes();

    const getSetting = () => {
        serverGet(`${apiConst.getaccountsettings}/-/TMZ`).then((res) => {
            try {
                setAccountTimeZOne(res.data);
            } catch (e) {
                console.log(e);
            }
        })
    }


    const getList = () => {

        const dur_name = getSearchParams("dn");
        const cons_mode = getSearchParams("m");
        progParams(true);
        serverGet(apiConst.getmodes + account_settings).then((res) => {
            progParams(false);
            try {
                if (res?.data !== null && res?.status == 1) {
                    const duration_types = res.data?.filter((list) => list?.group == 'schedule_duration' && list?.accountCategoryId != null);
                    const encounter_types = res.data?.filter((list) => list?.group == 'encounter_mode' && list?.accountCategoryId != null);

                    //SELECT CONSULTATION METHOD 

                    if (cons_mode) {
                        setencIndex(encounter_types?.findIndex(item => item.code == cons_mode));
                    } else {
                        setMode(encounter_types[0]?.code);
                        setencIndex(0);
                    }

                    //SELECT DURATION METHOD 

                    if (dur_name) {
                        setDurationId(duration_types.find(item => item?.name == getSearchParams("dn"))?.id);
                        setdurationIndex(duration_types.findIndex(item => item?.name == getSearchParams("dn")));
                    } else {
                        setDurationName(duration_types[0]?.name);
                        setDurationId(duration_types[0]?.id);
                        updateSearchParams("did", duration_types[0]?.id);
                        setdurationIndex(0);
                    }
                    setDuration(duration_types);
                    setEncounter(encounter_types);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    //console.log(durationId) showTimeSlot
    const gettimeslot = async () => {
        try {
            //&& currentWeek <= totalCalenderWeek
            if (durationId && showTimeSlot) {
                showLoaderProgress();
                const member_id = getSearchParams("memberid");
                const speciality_id = getSearchParams("spid");
                const fmid = getSearchParams("fmid");
                const residing_id = getSearchParams("rsid") ? `&rid=${getSearchParams("rsid")}` : '';
                const _brtype = getSearchParams("brtype");
                const patient_member_id = await getMemberId();

                const _methodId = _brtype == "RW8" ? 2 : _brtype == "GT6" ? 3 : _brtype == "SD3" ? 1 : "1"
                //TO STORE ENCOUNTER ID & DURATION ID

                updateSearchParams("did", duration.find(item => item?.id == durationId)?.id);
                updateSearchParams("emid", encounter.find(item => item?.code == mode)?.id);

                let dateArray = [];
                const currentDate = new Date();


                for (let i = 0; i < slotsPerWeek; i++) {
                    const newDate = new Date(currentDate);
                    newDate.setDate(currentDate.getDate() + (parseInt(currentWeek - 1) * slotsPerWeek) + i);
                    dateArray.push(newDate);
                }


                if (currentWeek == totalCalenderWeek) {
                    const totalDays = 7 * totalWeek;
                    let _remainingDays = (slotsPerWeek - (totalDays % slotsPerWeek)) % slotsPerWeek;

                    let previousDates = [];
                    for (let i = _remainingDays; i > 0; i--) {
                        let newDate = new Date(dateArray[0]);
                        newDate.setDate(dateArray[0].getDate() - i);
                        previousDates.push(newDate);
                    }

                    const _remaining = dateArray.slice(0, slotsPerWeek - _remainingDays)
                    dateArray = [...previousDates, ..._remaining];
                }

                let startOfDay = new Date(dateArray[0]);
                startOfDay.setHours(0, 0, 0, 0);

                let endOfDay = new Date(dateArray[dateArray.length - 1]);  //slotDays[slotDays.length - 1]
                endOfDay.setHours(23, 59, 59, 999);

                let startOfDayUtc = Moment.utc(startOfDay).format("YYYY-MM-DD hh:mm:ss A");
                let endOfDayUtc = Moment.utc(endOfDay).format("YYYY-MM-DD hh:mm:ss A");

                setSlotsTime("loading");

                const res = await serverGet(`${apiConst.gettimeslot}?fdt=${startOfDayUtc}&tdt=${endOfDayUtc}&did=${durationId}&sid=${speciality_id}&pmid=${fmid ? fmid : patient_member_id}${member_id ? `&memberId=${member_id}` : ""}${residing_id ? residing_id : ""}&mid=${_methodId}`)
                //progParams(false);                  
                if (res?.status == 1) {
                    if (res?.data && res?.data?.length > 0) {
                        // setBookingDate(null);
                        setSlotsTime(res?.data);
                        selectDefaultDate(res?.data, dateArray);
                    } else {
                        setSlotsTime([]);
                        //setIsEmptySlot(true);
                    }
                }
                hideLoaderProgress();
            }
        } catch (e) {
            console.log(e);
        }
    };



    //function generateDateArray() {
    //    const dateArray = [];
    //    const currentDate = new Date();
    //    for (let i = 0; i < 7; i++) {
    //        const newDate = new Date(currentDate);
    //        newDate.setDate(currentDate.getDate() + i);
    //        dateArray.push(newDate);
    //    }

    //    //setSlotDays(dateArray);
    //}


    const selectduration = (eindex, dindex, value, item) => {
        setDurationId(item?.id)  // to set duration id based to send to api
        setDurationName(item?.name) // to set duration name based on this  duration  name showing in flow summary
        setdurationIndex(dindex); // to set duration index based on this  select duration  
        setencIndex(eindex);  // to set enc index based on this  select enc  mode
        setMode(value); // to set cons mode 
        setSelectedSlot('');

        //console.log(value)
    }



    const handleChange = (value, eindex) => {
        setMode(value);
        setencIndex(eindex);
    }

    const navigateReason = (id, data) => {

        let providerId = ""

        if (id) {
            const memberProviders = JSON.parse(id);
            providerId = memberProviders[0]?.ProviderId
        }
        updateSearchParams("emid", encounter.find(item => item?.code == mode)?.id);
        updateSearchParams("selectedd", bookingDate);

        const _brtype = getSearchParams("brtype"); // Schedule method book now or shared calender _brtype == "GT6" ? 3 :
        const member_id = _brtype == "GT6" ? "" : _brtype == "RW8" ? providerId : getSearchParams("memberid");
        const app_date = getSearchParams("ad");
        //const time_slot = urlSearchParams.get('ts');
        let time_slot = selectedSlot;
        const app_mode = getSearchParams("m");
        const app_dur = getSearchParams("dn");
        const min_p = getSearchParams("minP");
        const speciality_id = getSearchParams("spid") ? `spid=${getSearchParams("spid")}` : '';
        const residing_id = getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : '';
        const duration_id = getSearchParams("did");
        const encounter_id = getSearchParams("emid");
        const parent_id = getSearchParams("parent");
        const f_typeid = getSearchParams("ftypeid");
        const fmid = getSearchParams("fmid"); // familymemberid

        if (_brtype == appConst.resourceCodes.consultNowWithoutProvider) {
            //time_slot = Moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss");
            time_slot = getUtcTimeNow();
        }


        navigate(`/reason?memberid=${member_id}&did=${duration_id}${data?.phone ? `&snum=${data?.phone}` : ""}&emid=${encounter_id}&ad=${app_date}&ts=${encodeURIComponent(time_slot)}&m=${app_mode}&dn=${app_dur}${speciality_id ? `&${speciality_id}` : ""}${fmid ? `&fmid=${fmid}` : ""}${residing_id ? `&${residing_id}` : ""}${min_p ? `&minP=${min_p}` : ""}${parent_id ? `&parent=${parent_id}` : ""}${f_typeid ? `&ftypeid=${f_typeid}` : ""}${_brtype ? `&brtype=${_brtype}` : "&brtype=SD3"} `);
    }



    //console.log(bookingDate);

    //"2024-06-18T19:00:00"

    const onsubmit = (data) => {
        if (mode != appConst.encounterModes.phone && mode != appConst.encounterModes.phoneOnline) {
            data["phone"] = null;
            removeSearchParam("snum");
        }

        if (data?.phone) {
            updateSearchParams('snum', data["phone"]);
        }

        if (showTimeSlot && (!mode || !selectedSlot)) {
            return notifyToast(339);
        }

        const _brtype = getSearchParams("brtype");

        if (_brtype == appConst.resourceCodes.sharedCalender || _brtype == appConst.resourceCodes.consultNowWithoutProvider) {
            return getAvailableProvider(data);
        }

        navigateReason("", data);
    }

    //console.log(selectedSlot);

    const selectDefaultDate = (slots, days) => {

        let temp = [];
        const temp2 = [];

        if (Array.isArray(slots) && slots.length > 0) {

            days?.map((item) => {
                temp = [];
                slots.map((list) => {
                    let _formatedSystemTime = timeFormat(list?.startDate, "YYYY-MM-DD hh:mm A");
                    if (new Date(Moment(item, "YYYY-MM-DD hh:mm A")).getDate() == new Date(Moment(_formatedSystemTime, "YYYY-MM-DD hh:mm A ")).getDate()) {
                        temp.push(list);
                    }
                })

                const _isAvailable = temp?.some((list) => list?.isAvailable && list?.isConsultationAllowed);
                temp2.push({
                    date: item,
                    isAvailableClass: _isAvailable ? "" : "no-slots"
                })
            })
            const _defaultDate = temp2?.find((list) => list?.isAvailableClass == "");

            if (_defaultDate && !bookingDate) {
                setBookingDate(_defaultDate?.date);
            }

            //if (currentWeek <= totalCalenderWeek && !_defaultDate) {
            //    setCurrentWeek(currentWeek + 1);
            //}

            setSlotDays(temp2);
        }
    }



    const getAvailableProvider = async (data) => {

        try {
            showLoaderProgress();
            let startOfDay = new Date(bookingDate);
            startOfDay.setHours(0, 0, 0, 0);

            let endOfDay = new Date(bookingDate);  //slotDays[slotDays.length - 1]
            endOfDay.setHours(23, 59, 59, 999);

            let startOfDayUtc = Moment.utc(startOfDay).format("YYYY-MM-DD hh:mm:ss A");
            let endOfDayUtc = Moment.utc(endOfDay).format("YYYY-MM-DD hh:mm:ss A");

            const speciality_id = getSearchParams("spid");
            const residing_id = getSearchParams("rsid");
            const duration_id = getSearchParams("did") || durationId;
            const fmid = getSearchParams("fmid");
            const _brtype = getSearchParams("brtype");
            const _methodId = _brtype == "RW8" ? 2 : _brtype == "GT6" ? 3 : _brtype == "SD3" ? 1 : "1"
            const patient_member_id = localStorage.getItem("memberId");
            const time_slot = getSearchParams("ts");
            const formData = {
                scheduleOn: time_slot,
                durationId: duration_id,
                fromDate: startOfDayUtc,
                toDate: endOfDayUtc,
                specialityId: speciality_id,
                patientMemberId: fmid ? fmid : patient_member_id,
            }

            if (residing_id) {
                formData.residingStateId = residing_id
            }

            const sharedCalenderPath = `&sdt=${formData.scheduleOn}&fdt=${formData.fromDate}&tdt=${formData.toDate}`

            const res = await serverGet(`${apiConst.getAvailableProvider}?mid=${_methodId}&did=${formData.durationId}&sid=${formData.specialityId}&pmid=${formData.patientMemberId}${residing_id ? `&rid=${residing_id}` : ''}${showTimeSlot ? sharedCalenderPath : ""}`)
            if (res?.status == 1 && res?.data) {
                navigateReason(res?.data, data);
            } else {
                notifyToast(232);
            }

        } catch (e) {
            console.log(e)
        } finally {
            hideLoaderProgress();
        }
    }


    const updateFieldValue = (value) => {
        setValue("phone", value)
    }


    const handleCalenderChange = (type) => {
        removeSearchParam("ad");
        setBookingDate(null);

        let _week = parseInt(currentWeek);

        if (type == "increment") {
            setCurrentWeek(_week + 1);
        } else if (type == "decrement") {
            setCurrentWeek(_week - 1);
        }

    }
    useLayoutEffect(() => {
        let _orderDetails = localStorage.getItem("orderDetail")

        if (_orderDetails) {
            _orderDetails = JSON.parse(_orderDetails);
            let _stateId = _orderDetails?.profile?.State
            if (_stateId) {
                updateSearchParams("rsid", _stateId)
            }
        }
    }, [])
    useLayoutEffect(() => {
        const _brtype = getSearchParams("brtype");
        if (_brtype != "GT6") {
            setShowTimeSlot(true);
        }
    }, [])


    useEffect(() => {
        const refId = getSearchParams("refId");

        if (refId)
            serverGet("externalorder/getsessdetail/" + refId).then(res => {

                if (res?.orderDetail) {
                    localStorage.setItem("orderDetail", res.orderDetail);
                }
            });

    }, []);

    useEffect(() => {

        if (calenderWeeks) {
            const _weeeks = appConst.calendarWeeks[calenderWeeks];
            if (_weeeks) {
                setTotalWeek(_weeeks);
                setTotalCalenderWeek(Math.ceil(7 * _weeeks / slotsPerWeek));
            }
        }

    }, [calenderWeeks])

    //console.log(totalWeek);


    useEffect(() => {
        const returnSlots = () => {
            updateSearchParams('ad', bookingDate);
            let temp = [];
            if (Array.isArray(slotTime) && slotTime.length > 0) {
                slotTime.map((list) => {
                    let _formatedSystemTime = timeFormat(list?.startDate, "YYYY-MM-DD hh:mm A");
                    if (new Date(Moment(bookingDate, "YYYY-MM-DD hh:mm A")).getDate() == new Date(Moment(_formatedSystemTime, "YYYY-MM-DD hh:mm A ")).getDate()) {
                        temp.push(list);
                    }
                })
            }
            const _isAvailable = temp?.some((list) => list?.isAvailable && list?.isConsultationAllowed);
            if (_isAvailable) {
                setSelectedSlotsTime(temp);
            } else {
                setSelectedSlotsTime([]);
            }
        }
        returnSlots();
    }, [bookingDate, slotTime])

    useEffect(() => {

        getList();
        /// generateDateArray();
        getSetting();
        const _sid = getSearchParams("spid"); //specialityId
        const _cId = getSearchParams("ftypeid"); //specialityId
        const _week = getSearchParams("week"); //specialityId
        //console.log(_week)
        if (_week) {
            setCurrentWeek(parseInt(_week))
        }
        if (_sid)
            getSchedulePricing(_sid, _cId);
    }, [])

    useEffect(() => {
        updateSearchParams("week", currentWeek);
    }, [currentWeek])

    useEffect(() => {
        document.title = "Book appointment";
        const app_date = getSearchParams("ad");
        if (app_date) {
            setBookingDate(new Date(app_date));
        }

        const time_slot = getSearchParams("ts");
        const app_mode = getSearchParams("m");
        const app_dur = getSearchParams("dn");

        setSelectedSlot(time_slot || '');
        setMode(app_mode || '');
        setDurationName(app_dur || '');
    }, []);

    useEffect(() => {
        updateSearchParams('memberid', getSearchParams("memberid"));
        updateSearchParams('ad', bookingDate);
        updateSearchParams('m', mode);
        updateSearchParams('dn', durationName);

        gettimeslot();
    }, [durationId, mode, currentWeek])

    useEffect(() => {
        if (selectedSlot)
            updateSearchParams("ts", selectedSlot);

    }, [selectedSlot])


    return (
        <form onSubmit={handleSubmit(onsubmit)} autoComplete="off">
            <div className="px-2 px-md-0">
                <ScheduleHeader title={getLanguageContent(showTimeSlot ? "chsetmslot" : "selcmo")} />
                <div className="d-flex flex-md-row flex-column">
                    <DoctorSummary />
                    <div className="flex-grow-1">
                        <div className="card border-0 p-2 p-md-4">
                            <h5>{getLanguageContent("appttype")}</h5>
                            <p>{getLanguageContent("prefmthd")}</p>
                            {encounter?.filter((list) => list?.accountCategoryId != null).map((list, index) => (
                                <div className="card mb-3" key={`mode ${index}`}>
                                    <div className="card-body">
                                        <div className="form-check p-2 ms-3">
                                            <input checked={mode == list?.code} value={mode} onClick={(e) => handleChange(list?.code, index)} className="form-check-input" type="radio" name={list?.group} id={list?.name} />
                                            <label className="form-check-label fw-bold" htmlFor={list?.name}>
                                                {list?.name}
                                            </label>
                                        </div>
                                        <div className="p-1">
                                            {list?.id == "1" ? getLanguageContent("medexprt") : list?.id == "2" ? getLanguageContent("dgtpltform") : list?.id == "3" ? getLanguageContent("phndesc") : list?.id == "4" ? getLanguageContent("hediph") : ""}
                                        </div>
                                        {(mode == list?.code && showTimeSlot) &&
                                            <div className='p-1'>
                                                {duration?.filter((list) => list?.accountCategoryId != null)?.map((durations, i) => (
                                                    <button onClick={() => selectduration(index, i, list?.code, durations)} key={`duration ${i}`} type="button" className={`btn btn-${(durationIndex === i && encIndex === index) ? '' : 'outline-'}primary rounded-pill me-2 mb-2`}>{durations?.name}</button>
                                                ))}
                                            </div>
                                        }
                                        {((list?.code == appConst.encounterModes.phone && mode == appConst.encounterModes.phone) || (list?.code == appConst.encounterModes.phoneOnline && mode == appConst.encounterModes.phoneOnline)) && (
                                            <>
                                                <PhoneField name={"phone"} getLanguageContent={getLanguageContent} updateFieldValue={updateFieldValue} defaultNumber={getSearchParams("snum") || profileContext?.resProfile?.phone} errors={errors}
                                                    register={register} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {/*To change the timezone <a href="#" className="alert-link">click here</a> profileContext?.resProfile?.timeZoneId ? profileContext?.resProfile?.timeZoneId :*/}

                            {/*<p>Select your time slot</p> */}

                            {(slotTime != "loading" && showTimeSlot && bookingDate && slotDays?.length > 0) && (
                                <>
                                    {accountTimeZone?.length > 0 &&
                                        <div className="p-3 mb-3 bg-warning border border-warning bg-gradient text-dark bg-opacity-10" role="alert">
                                            <strong>{getLanguageContent("dsplyslt")}</strong>
                                        </div>
                                    }
                                    <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row">
                                        {bookingDate && (
                                            <div className="flex-grow-1 fs-4 py-2 text-center bg-light me-sm-4 me-lg-4 mb-md-2 mb-2">{dateFormat(bookingDate, "MMM YYYY")}</div>
                                        )}
                                        <div className="d-flex flex-shrink-0 gap-sm-3 gap-md-2 gap-lg-4 time-slot-header">

                                            {currentWeek > 1 && (
                                                <span className="d-flex align-items-center border calender-arrow pointer-class" onClick={() => handleCalenderChange("decrement")}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                                </svg></span>
                                            )}

                                            {slotDays?.map((list, index) => {
                                                return (
                                                    <>
                                                        <a key={`days ${index}`} onClick={() => {
                                                            setSelectedSlot('')
                                                            setBookingDate(list?.date)
                                                            //setBookingDateClick(true)
                                                        }} className={`flex-fill ${bookingDate?.getDate() == list?.date?.getDate() ? "active" : ''} `} id="pills-1-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" aria-current="page">
                                                            <div className={`mx-auto ${list?.isAvailableClass ? bookingDate?.getDate() == list?.date?.getDate() ? `active-${list?.isAvailableClass}` : `${list?.isAvailableClass}` : ""}`}>{Moment(list?.date).format(`D`)}</div>
                                                            {Moment(list?.date).format(`ddd`)}
                                                        </a>
                                                    </>
                                                )
                                            })}
                                            {currentWeek < totalCalenderWeek && (
                                                <span className="d-flex align-items-center border calender-arrow pointer-class " onClick={() => handleCalenderChange("increment")}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                                </svg></span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="tab-content mt-3 mb-3" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-1-tab" tabIndex="0">
                                            {(Array.isArray(selectedSlotTime) && selectedSlotTime?.length > 0) ? (
                                                <div className="mx-auto">
                                                    <div className="p-1 d-flex flex-wrap">
                                                        {selectedSlotTime?.map((list, index) => (
                                                            <button
                                                                disabled={!list?.isAvailable}
                                                                onClick={() => setSelectedSlot(list?.startDate)}
                                                                key={`timeslot ${index}`}
                                                                type="button"
                                                                className={`  
                                                    ${selectedSlot == list?.startDate ? "btn btn-primary" : "btn btn-outline-primary"} me-2 mb-2 
                                                    ${list?.isAvailable ? "" : "text-decoration-line-through"} `}
                                                                style={{ minWidth: "100px" }}>
                                                                {timeFormat(list?.startDate)}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    <ActionStatus description={`${getLanguageContent("prvdrunavl")}.`} button={""} imageid="#svg_failed" onsyncchange={""} type="search" descriptionClass={"fw-normal"} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            )}


                            {((showTimeSlot && slotTime != "loading") || (!showTimeSlot && durationId && mode)) && (
                                <div className="text-end"><button className="btn btn-lg btn-primary">{getLanguageContent("prced")}</button></div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}


const PhoneField = ({ name, updateFieldValue, register, errors, defaultNumber, getLanguageContent }) => {

    useEffect(() => {

        if (defaultNumber) {
            updateFieldValue(defaultNumber)
        }
    }, [defaultNumber])


    return (
        <>
            <div className="my-3">
                <label htmlFor={name} className="form-label"> {getLanguageContent("phonum")} <span className="text-danger">*</span></label>
                <input type="text" autoFocus={true} className="form-control body-secondary-color" id={name} placeholder={getLanguageContent("entphu")}  {...register(name, { required: getMsg(301), pattern: formValidation.number.pattern, maxLength: { value: 15, message: getMsg("305") } })} />
                {errors.phone?.message && <p className="text-danger">{errors.phone?.message}</p>}
            </div>
            <span>
                {getLanguageContent("pphpro")}
            </span>
        </>
    )
}


