import React, { useEffect, useState, useContext } from 'react';
import { serverGet, serverPut } from '../../../middleware/http';
import { apiConst, appConst, resourceConst } from '../../../services/constant/constant';
//import MemberSpecialty from '../speciality/MemberSpeciality';
import AppointmentTypes from './Appointment';
import AppointmentList from './AppointmentList';
import { useNavigate } from 'react-router-dom';
import { ecnSession, getMemberId, isEnabled } from '../../../services/utils/validation';
import { useProfile } from '../../../services/utils/profileContext';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import ProgressContext from '../../../services/utils/progress';
import { useManageResource } from '../../../services/Context/ManageResourceContext';
import MemberPlanWidget from '../MemberPlan/MemberPlanWidget';
import PendingAppointment from './PendingAppointment';

const Dashboard = () => {
    const { patientInfo, checkUserProfile, getFamilyList } = useProfile();
    const { accountSettings, getAccountsettingValue } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();
    const { progParams } = useContext(ProgressContext);
    const [specialityList, setSpecialityList] = useState([]);
    //const [recentApointment, setRecentApointment] = useState(null);
    const [resourceAction, setResourceAction] = useState([]);
    //const [calenderResourceAction, setCalenderResourceAction] = useState([]);
    const [bookingMethods, setBookingMethods] = useState(null);
    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();
    const [memberPlan, setMemberPlan] = useState("");
    const [planCodes] = useState([{ name: "Mental Health", code: "P2, P3, P6, P7, P8, P9, P10" }, { name: "Care Navigation", code: "P3, P4, P6, P7, P8, P9, P10" }, { name: "Functional Medicine", code: "P1, P2, P3, P4, P5, P6, P7, P8, P9, P10" }, { name: "Urgent Care", code: "P1, P2, P3, P4, P5, P6, P7, P8, P9, P10" }, { name: "Primary Care", code: "P1, P2, P3, P4, P5, P6, P7, P8, P9, P10" }, { name: "Muscle Joint", code:"P8, P9"}])
    const navigate = useNavigate();


    const getList = () => {
        serverGet(apiConst.getproviderspeciality).then((res) => {
            try {
                if (res?.data)
                    setSpecialityList(res?.data);
            } catch (e) {
                console.log(e)
            }
        })
    }


    const booknow = async (type) => {

        try {
            ecnSession("clear");
            progParams(true);
            const withoutProvider = type == "RW8" || type == "GT6"
            const residingStateRes = await serverGet(`${apiConst.getlicenseregion}`);
            const stateRes = residingStateRes?.status == 1 && residingStateRes?.data?.states?.length > 0;
            const isResidingState = isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1);
            const residingId = isResidingState && patientInfo?.residingStateId ? `rsid=${patientInfo?.residingStateId}` : ""

            if (isResidingState && stateRes) {
                navigate(`/residingstate?id=${specialityList?.length == 1 ? specialityList[0]?.id : "y"}${residingId ? `&${residingId}` : ""}&brtype=${type}`);
            } else if (isEnabled(accountSettings, "ENSP", 1)) {
                if (specialityList?.length == 1) {
                    if (withoutProvider) {
                        return navigateSharedCalender(specialityList[0]?.id, type);
                    }

                    const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-`);
                    if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                        return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}&minP=${ProviderRes?.data[0]?.minimumPrice}&sinp=t&brtype=${type}`);
                    }
                    return navigate(`/provider?spid=${specialityList[0]?.id}&brtype=${type}`);
                } else {
                    return navigate(`/specialty?brtype=${type}`);
                }
            } else {
                if (specialityList?.length == 1) {
                    if (withoutProvider) {
                        return navigateSharedCalender(specialityList[0]?.id, type);
                    }
                    const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-`);
                    if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                        return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}&minP=${ProviderRes?.data[0]?.minimumPrice}&sinp=t&brtype=${type}`);
                    }
                } else if (withoutProvider) {
                    return navigateSharedCalender(specialityList[0]?.id, type);
                } else {
                    return navigate(`/provider?brtype=${type}`);
                }
            }

        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);
        }

    }

    const navigateSharedCalender = async (spid, type) => {

        const _isProfile = await checkUserProfile();
        const addProfileUrl = `/addprofile?spid=${spid}&brtype=${type}`;
        const _identificationUrl = `/idverification/upload?spid=${spid}&brtype=${type}`;
        const bookingUrl = `/booking?spid=${spid}&brtype=${type}`;
        const _familyMemberUrl = `/selectfamilymember?spid=${spid}&brtype=${type}`


        const _isFamilyMember = await getFamilyList();

        const redirectUrl = {
            path: _isFamilyMember ? _familyMemberUrl : bookingUrl,
            bookingUrl: bookingUrl,
            familyMemberUrl: _familyMemberUrl,
            profileUrl: addProfileUrl,
        }

        if (!_isProfile?.pofileCompleted) {
            navigate(addProfileUrl);
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
        } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
            navigate(_identificationUrl);
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
        } else if (_isFamilyMember) {
            navigate(_familyMemberUrl);
        } else {
            navigate(bookingUrl);
        }
    }


    const getPlanList = async (patient) => {
        try {

            let _memberId;

            if (patient?.parentId) {
                _memberId = patient?.parentId
            } else {
                _memberId = await getMemberId();
            }

            if (_memberId) {
                const res = await serverGet(`${apiConst.getPlan}${_memberId}`)

                if (res?.data && res?.status == 1) {
                    getPlanCode(res?.data?.planId)
                }
            }

        } catch (e) {
            console.log(e)
        }

    }


    const getPlanCode = (id) => {
        serverGet(`${apiConst.getPlanCode}${id}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data) {
                    setMemberPlan(res?.data?.code)
                }
            } catch (e) {
                console.log(e)
            }
        })
    }


    const showPlanContainer = (index, grid) => {
        const planLength = planCodes.filter((list) => list.code.includes(memberPlan))?.length;
        const widgetsInRow = Math.min(grid, planLength - Math.floor(index / grid) * grid);

        if (grid == 3) {
            if (widgetsInRow === 1) {
                return 12;
            } else if (widgetsInRow === 2) {
                return 6;
            } else {
                return 4;
            }
        } else if (grid == 2) {
            if (widgetsInRow === 1) {
                return 12;
            } else {
                return 6;
            }
        }


    }

    const showAppoinmentWidget = (types) => {
        let length = 0
        if (types) {
            Object.keys(types)?.map((list) => {
                if (types[list]?.action?.length > 0) {
                    length++;
                }
            })
        }

        return length;
    }

    useEffect(() => {
        const _actions = getCurrentResourceAction("CNS", "DSB"); // appointment widget
        setResourceAction(_actions);
        const { sharedCalender, consultNowWithoutProvider, consultLaterWithProvider } = appConst?.resourceCodes


        const _bookingTypes = {
            consultLaterWithProvider: {
                title: getLanguageContent("pckdctr"),
                desc: getLanguageContent("prferdprofession"),
                action: getCurrentResourceAction(consultLaterWithProvider, "DSB"),
                code: consultLaterWithProvider,
                btnText: getLanguageContent("docboknw")
            },
            sharedCalender: {
                title: getLanguageContent("pckprv"), //consultLaterWithoutProvider
                desc: getLanguageContent("nedfnd"),
                action: getCurrentResourceAction(sharedCalender, "DSB"),
                code: sharedCalender,
                btnText: getLanguageContent("boknw")

            },
            consultNowWithoutProvider: {
                title: getLanguageContent("inbook"),
                desc: getLanguageContent("coneav"),
                action: getCurrentResourceAction(consultNowWithoutProvider, "DSB"),
                code: consultNowWithoutProvider,
                btnText: getLanguageContent("cosnow")

            },
        }

        setBookingMethods(_bookingTypes)

    }, [resourceList, patientInfo])

    useEffect(() => {
        document.title = "Dashboard";
        getList();

    }, [patientInfo]);



    useEffect(() => {

        if (patientInfo) {
            getPlanList(patientInfo);
        }

    }, [patientInfo])
    //console.log(resourceAction);

    return (
        <>
            <PendingAppointment />
            {bookingMethods && (
                <div className={`row row-cols-1 row-cols-lg-${showAppoinmentWidget(bookingMethods) >= 2 ? "2" : ""} row-cols-xxl-${showAppoinmentWidget(bookingMethods) == 3 ? "3" : showAppoinmentWidget(bookingMethods) == 2 ? "2" : ""}`}>
                    {Object.keys(bookingMethods)?.map((list) => (
                        <>
                            {actionExists(bookingMethods[list]?.action, resourceConst?.actions?.view) && (
                                <>
                                    <div className="col d-flex align-items-stretch g-4">
                                        <AppointmentTypes showBtn={actionExists(bookingMethods[list]?.action, resourceConst?.actions?.create)} title={bookingMethods[list]?.title} description={bookingMethods[list]?.desc} btn={bookingMethods[list]?.btnText} booknow={booknow} type={bookingMethods[list]?.code} />
                                    </div>
                                </>
                            )}
                        </>
                    ))}
                </div>
            )}


            {(memberPlan && patientInfo) && (
                <div className="row mt-3">
                    {planCodes?.filter((list) => list?.code.includes(memberPlan))?.map((item, index) => (
                        <div className={`col-lg-${showPlanContainer(index, 2)} col-xxl-${showPlanContainer(index, 3)} mb-3 d-flex align-items-stretch`}>
                            <MemberPlanWidget item={item} memberPlan={memberPlan} booknow={booknow} />
                        </div>
                    ))}
                </div>
            )}
            {patientInfo && (
                <AppointmentList view={actionExists(resourceAction, resourceConst?.actions?.view)} patientInfo={patientInfo} />

            )}
        </>
    );
};

export default Dashboard;

