import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { serverGet } from '../../../middleware/http'
import { apiConst, appConst } from '../../../services/constant/constant'
import { dateTimeFormat, isEnabled, timeFormat, getScheduleColor } from '../../../services/utils/validation'
import ActionStatus from '../../../services/utils/actionStatus';
import ProgressContext from '../../../services/utils/progress';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useProfile } from '../../../services/utils/profileContext';
import { useDoctor } from '../../../services/Context/DoctorContext';

const AppointmentList = ({ view }) => {
    //const accountSettingCode = "ENJN,BECON,AFCON,VCP";
    const [scheduleList, setScheduleList] = useState([]);
    //const [scheduleSetting, setScheduleSetting] = useState([]);
    const navigate = useNavigate();
    const { progParams } = useContext(ProgressContext);
    const { accountSettings, accountDate, isGWF } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();
    const { patientInfo } = useProfile();

    const { providerprofile } = useDoctor();

    const getList = () => {
        setScheduleList("loading");
        progParams(true);
        serverGet(apiConst.getschedulelist).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    let _response = res?.data;
                    if (Array.isArray(_response) && _response?.length > 0) {
                        //if (_response[0]?.recentInitiatedEncounterId !== null && _response[0]?.recentInitiatedEncounterId) {
                        //    setRecentApointment(_response[0]?.recentInitiatedEncounterId);
                        //}
                        _response?.forEach((parentItem) => {
                            const child_items = _response?.filter((childItem) => childItem?.parentId === parentItem?.encounterId);
                            if (child_items?.length > 0) {
                                parentItem.child = child_items;
                                _response = _response.filter((item) => !child_items.includes(item));
                            }
                        });
                    }
                    setScheduleList(_response);
                } else {
                    setScheduleList(null);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const encounterDetail = (encid, list) => {
        navigate(`/visit/detail?eid=${encid}&spid=${list?.specialityId}${list?.memberParentId && !patientInfo?.parentId ? `&fmid=${list?.memberId}` : ""}&methodid=${list?.methodId}`);
    }


    const joinCallButton = (url) => {
        window.open(url, '_blank');

    }

    const followAppointment = async (list) => {
        try {
            progParams(true);
            const encounterResult = await serverGet(apiConst.getencounter + list?.encounterId);
            return navigate(`/booking?${followUrl(encounterResult?.data, list)}`);
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);
        }
    }

    function followUrl(value, list) {
        const isResidingState = isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1);
        const residingId = isResidingState && patientInfo?.residingStateId ? `rsid=${patientInfo?.residingStateId}` : ""

        const providerId = value?.providerId;
        const spid = value?.specialityId; // parent specialty id
        const m = value?.encounterModeCode; // encounterMode
        const dn = value?.duration; // encounterDurationname

        return `memberid=${providerId}&spid=${spid}&m=${m}&dn=${dn}&parent=${value?.id}&ftypeid=2${residingId ? `&${residingId}` : ""}${list?.memberParentId && !patientInfo?.parentId ? `&fmid=${list?.memberId}` : ""}`
    }

    const providerUrl = async (id, typeId) => {
        try {
            if (typeId != 5) {
                progParams(true);
                const res = await providerprofile(id);
                if (res?.status == 1) {
                    const _response = res?.data;
                    const _specialities = _response?.specialities?.filter((list) => list?.id != null)
                    const _specialityId = (Array.isArray(_specialities) && _specialities?.length > 0) ? _specialities[0]?.specialityId : "";
                    return navigate(`/profiledetail?memberid=${_response?.member?.id}&spid=${_specialityId}&minP=${_response?.minimumPrice?.price}`);
                }
            }
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);

        }


    }


    //console.log(scheduleList)

    useEffect(() => {
        //getSetting();
        getList();
    }, [])


    return (
        <>
            {view && (
                <>
                    <h4 className="mb-4 ">{getLanguageContent("csltion")}</h4>
                    <div className="card border-0">
                        <div className="card-body p-0 px-2 py-md-3 px-md-4">
                            {(Array.isArray(scheduleList) && scheduleList?.length > 0) && scheduleList?.filter((item) => item?.id != "00000000-0000-0000-0000-000000000000")?.map((list) => (
                                <>
                                    <div className="my-3 border rounded p-2" key={list?.id}>
                                        <div className="d-flex p-1">
                                            <div className="d-flex flex-column me-auto">
                                                <div>
                                                    <span>
                                                        <span className="">{list?.patientName}</span>
                                                        <span className=""> | </span>
                                                        <span className="text-primary fw-bold border-bottom border-2 me-1 pointer-class" onClick={() => encounterDetail(list?.encounterId, list)}>{list?.encounterNo}</span>
                                                        <span className=""> | </span>
                                                        <span className="me-1">{list?.consultationType}</span>
                                                        <button type="button" style={{ cursor:"text"}} className={`py-0 btn ${getScheduleColor(list?.scheduleStatusId)} btn-sm`}>{list?.status}</button>
                                                    </span>
                                                </div>
                                                <div>
                                                    <small className="text-secondary">{list?.specialityName}</small>
                                                    {(list?.reasonCode && list?.reasonCode?.trim() != "OTH") && (
                                                        <small className="text-secondary"> | {list?.reason}</small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex">

                                                {((list?.encounterModeCode == appConst.encounterModes.online || list?.encounterModeCode == appConst.encounterModes.phoneOnline) &&
                                                    !isGWF &&
                                                    list?.joinUrl &&
                                                    accountSettings?.find(list => list?.settingCode == "VCP" && list?.value?.toLowerCase() != "none") &&
                                                    enableBtn(list?.scheduleOn, accountSettings, list?.scheduleStatusId)) ? (
                                                    <button type="button" className="btn btn-primary py-0 rounded-pill me-1 my-1" onClick={() => joinCallButton(list?.joinUrl)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                                                        </svg>
                                                        <span className="d-none d-lg-inline ms-1">{getLanguageContent("jncal")}</span>
                                                    </button>
                                                ) : ""}
                                                {/*&& list?.scheduleStatusId == 3*/}
                                                {(isEnabled(accountSettings, "FUA", 1) && list?.consultationTypeId == 1 && list?.scheduleStatusId == 3) && (
                                                    <>
                                                        <button onClick={() => followAppointment(list)} type="button" className="d-none d-lg-inline btn btn-outline-primary py-0 rounded-pill ms-1 my-1">
                                                            {getLanguageContent("fllwup")}
                                                        </button>
                                                        <div className="d-inline d-lg-none">
                                                            <div className="btn-group">
                                                                <button type="button" className="btn border-0 p-0" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                                                    <svg width="20" height="20" role="img">
                                                                        <use xlinkHref="#three_dots_menu"></use>
                                                                    </svg>
                                                                </button>
                                                                <ul style={{ minWidth: "auto" }} className="dropdown-menu dropdown-menu-end me-2">
                                                                    <li><a className="dropdown-item" onClick={() => followAppointment(list)} rel="noopener noreferrer">{getLanguageContent("fllwup")}</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="border-top border-2 p-1 ">
                                            <span><small className="text-primary">{getLanguageContent("pvdr")} - <span onClick={() => providerUrl(list?.providerId, list?.memberTypeId)} className={`${list?.memberTypeId != 5 ? "pointer-class border-bottom border-2" : ""} fw-bold`}>{list?.providerName}</span></small><small className="d-none d-md-inline mx-1 text-secondary">|</small><small className="d-block d-md-inline text-secondary">{list?.encounterMode} | {dateTimeFormat(list?.scheduleOn, accountDate?.value)} {timeFormat(list?.scheduleOn)} | {list?.duration}</small></span>
                                        </div>
                                    </div>
                                    {(list?.child && list?.child?.length > 0) && (
                                        <>
                                            {list?.child?.map((item, index) => (
                                                <div className="d-flex my-0" key={item?.id}>
                                                    <div className="d-flex align-items-center me-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                                        </svg>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="border rounded p-2">
                                                            <div className="d-flex p-1 ">
                                                                <div className="d-flex flex-column me-auto">
                                                                    <div>
                                                                        <span>
                                                                            <span>{item?.patientName}</span>
                                                                            <span> | </span>
                                                                            <span className="text-primary me-1 fw-bold border-bottom border-2 pointer-class" onClick={() => encounterDetail(item?.encounterId)}>{item?.encounterNo}</span>
                                                                            <span> | </span>
                                                                            <span className="me-1">{item?.consultationType}</span>
                                                                            <button type="button" style={{ cursor: "text" }} className={`py-0 btn ${getScheduleColor(item?.scheduleStatusId)} btn-sm`}>{item?.status}</button>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <small className="text-secondary">{item?.specialityName}</small>
                                                                        {(item?.reasonCode && item?.reasonCode?.trim() !== "OTH") && (
                                                                            <small className="text-secondary"> | {item?.reason}</small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex ">
                                                                    {((item?.encounterModeCode == appConst.encounterModes.online || item?.encounterModeCode == appConst.encounterModes.phoneOnline) &&
                                                                        !isGWF &&
                                                                        item?.joinUrl &&
                                                                        accountSettings?.find(item => item?.settingCode == "VCP" && item?.value?.toLowerCase() != "none") &&
                                                                        enableBtn(item?.scheduleOn, accountSettings, item?.scheduleStatusId)) ? (
                                                                        <button type="button" className="btn btn-primary py-0 rounded-pill me-1 my-1" onClick={() => joinCallButton(item?.joinUrl)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                                                                            </svg>
                                                                            <span className="d-none d-lg-inline ms-1">{getLanguageContent("jncal")}</span>
                                                                        </button>
                                                                    ) : ""}

                                                                </div>
                                                            </div>
                                                            <div className="border-top border-2 p-1 ">
                                                                <span><small className="text-primary">{getLanguageContent("pvdr")} - <span onClick={() => providerUrl(item?.providerId, item?.memberTypeId)} className={`${item?.memberTypeId != 5 ? "pointer-class border-bottom border-2" : ""} fw-bold`}>{item?.providerName}</span></small><small className="d-none d-lg-inline mx-1 text-secondary">|</small><small className="d-block d-lg-inline text-secondary">{item?.encounterMode} | {dateTimeFormat(item?.scheduleOn, accountDate?.value)} {timeFormat(item?.scheduleOn)} | {item?.duration}</small></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            ))}
                            {((scheduleList != "loading") && (scheduleList?.length === 0 || scheduleList == null || scheduleList?.filter((item) => item?.id != "00000000-0000-0000-0000-000000000000")?.length == 0)) && <ActionStatus description={"No records found"} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default AppointmentList

function enableBtn(date, enableJoin, status) {
    //console.log("out,", status)


    if (status != 1 && status != 5 && status != 2 && status != 3) {
        return false;
    }
    //console.log(enableJoin)
    let ENJN = []
    let BECON = []
    let AFCON = []

    // Convert the given date string to a JavaScript Date object
    //const givenDateTime = new Date(date);

    const convertDatetime = (datetime) => {
        return new Date(datetime + 'Z');
    };

    const givenDateTime = convertDatetime(date);
    // Get the current time
    const currentTime = new Date();

    // Calculate the time difference in minutes and days
    const timeDifferenceInMillis = givenDateTime - currentTime;
    const minuteDifference = Math.floor(timeDifferenceInMillis / (1000 * 60));
    const dayDifference = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60 * 24));
    const absDayDifference = Math.abs(dayDifference);

    if (enableJoin?.length > 0) {
        ENJN = enableJoin?.filter((list) => list?.settingCode === 'ENJN')
        BECON = enableJoin?.filter((list) => list?.settingCode === 'BECON')
        AFCON = enableJoin?.filter((list) => list?.settingCode === 'AFCON')
    }


    //console.log("in")

    if (ENJN[0]?.value == "1") {
        if (status == 3) {
            if (parseInt(AFCON[0].value) > 0 && absDayDifference >= 0 && absDayDifference <= AFCON[0].value) {
                return true;
            } else {
                return false;
            }
        } else if ((minuteDifference <= BECON[0].value && minuteDifference >= 0) || (currentTime.getTime() >= givenDateTime.getTime())) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }



    //if (ENJN[0]?.value == "1") {
    //    if (status == 3) {
    //        if (parseInt(AFCON[0].value) > 0 && absDayDifference >= 0 && absDayDifference <= AFCON[0].value && givenDateTime.getTime() < currentTime.getTime()) {
    //            return true;
    //        }
    //    } else if (minuteDifference <= BECON[0].value && minuteDifference >= 0) {
    //        return true;
    //    } else {
    //        return false;
    //    }
    //} else if (ENJN[0]?.value != "1") {
    //    if (currentTime.getTime() >= givenDateTime.getTime()) {
    //        return true;
    //    } else {
    //        return false;
    //    }
    //}
}



